.nav-container {
  position: fixed;
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  background-color: black;
  margin-bottom: 1px;
  z-index: 10;
}

#logo {
  padding-left: 5vw;
  padding-right: 10vw;
  flex-basis: 25vw;
  flex: 1;
}

.logo-img {
  max-width: 100%; /* Make the logo responsive */
  height: auto;
}

.navbar {
  flex: 3;
  z-index: 10;
}

.links {
  font-family: 'Buchanan', sans-serif;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  margin-left: -20vw;
}

.links * {
  box-sizing: border-box;
  transition: all 0.35s ease;
}

.links li {
  display: inline-block;
  list-style: outside none none;
}

.links a {
  color: #EADBC8;
  position: relative;
  text-decoration: none;
  font-size: 2vw;
  padding: 0.3em 1em;
  border-right: 3vw solid transparent;
}

.links a::before,
.links a::after {
  content: '';
  height: 1.4vw;
  width: 1.4vw;
  position: absolute;
  transition: all 0.35s ease;
  opacity: 0;
}

.links a::before {
  right: 0;
  top: 0;
  border-top: 0.3vw solid #ee8b48;
  border-right: 0.3vw solid #ee8b48;
  transform: translate(-100%, 50%);
}

.links a:after {
  left: 0;
  bottom: 0;
  border-bottom: 0.3vw solid #ee8b48;
  border-left: 0.3vw solid #ee8b48;
  transform: translate(100%, -50%);
}

.links a:hover:before,
.links a:hover:after {
  transform: translate(0, 0);
  opacity: 1;
}

.links a:hover {
  color: #0267ff;
}


.nav-decoration {
  position: fixed;
  top: 7vh;
  margin-top: -4%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background-image: url('./assets/images/outline_test_4.webp');
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 2;
}

/* ... (rest of your code) */

/* 
.blog-wall-art img{
  display: block;
  position: relative;
  transform: translate(0%, -400%) scale(1);
  width: 100%; 
  height: auto;
  
} */


.sticker {
  
}



.sticker img {
  display: block;
  position: absolute;
  transform: translate(345%, 170%) scale(1);
  height: auto;
  width: 20%; 
  z-index: 1;
  background-size: contain;
  background-repeat: no-repeat;
  
  
}

@keyframes pulsate {
  0% {
    transform: scale(.3);
  }
  50% {
    transform: scale(.4);
  }
  100% {
    transform: scale(.3);
  }
}





.arrow-div {
  width: 100%; /* Make sure the container takes the full width */
  height: auto;
  overflow: hidden; /* Hide any overflow */
  position: absolute;
  z-index: 2;

}

.arrow {
  display: block;
  width: auto; /* Set the width to 100% to make it scale with the container */
  height: auto; /* Maintain the aspect ratio while scaling down */
  transform: translate(30%, -20.4%) scale(.1);
}

.arrow img.pulsate {
  animation: pulsate 4s infinite;
  display: block;
  width: 100%; /* Set the width to 100% to make it scale with the container */
  height: auto; /* Maintain the aspect ratio while scaling down */
  transition: transform 0.3s ease; /* Add a smooth transition effect */

}





.cordon1 {
  background-image: url('./assets/images/cordon.webp');
  overflow: hidden;
  position: absolute;
  top: 5%; /* Adjust the top position as needed */

  width: 850%; /* Adjust the width as needed */
  height: 12%; /* Adjust the height as needed */
  background-size: contain; /* Adjust as needed */
  background-repeat: no-repeat;
  z-index: 1;
  transform: translateX(-39.8%) rotate(20deg);
}

.cordon2 {
  background-image: url('./assets/images/cordon.webp');
  overflow: hidden;
  position: absolute;
  top: 10%; /* Adjust the top position as needed */
  width: 850%; /* Adjust the width as needed */
  height: 12%; /* Adjust the height as needed */
  background-size: contain; /* Adjust as needed */
  background-repeat: no-repeat;
  z-index: 1;
  transform: translateX(-46.7%) rotate(-19.8deg);
}

.cordon3 {
  background-image: url('./assets/images/cordon.webp');
  overflow: hidden;
  position: absolute;
  top: 47%; /* Adjust the top position as needed */

  width: 850%; /* Adjust the width as needed */
  height: 12%; /* Adjust the height as needed */
  background-size: contain; /* Adjust as needed */
  background-repeat: no-repeat;
  z-index: 0;
  transform: translateX(-39.2%) rotate(17.6deg);
}


.home-screen-container {
  width: 100%; /* Make sure the container takes the full width */
  overflow: hidden; /* Hide any overflow */
  position: relative;
}

.home-screen-art {
  width: 100%; 
  height: auto; 
  
}




.test1 {
  background-color: rgba(48, 48, 48, 0.3); 
  border-radius: 1%;
  color: #EADBC8;
  position: absolute;
  display: block;
  padding-left: 1%;
  width: 50%;
  height: auto;
  transform: translate(20%, 120%);
  transition: transform 0.3s ease;
  overflow: hidden;
  z-index: 2;

}

.test2 {
  font-size: 3em;
}

.test3 {
  font-size: 1.2em;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

}




.project-container {
  position: relative;
}





.bg-dec {
  width: 100%; /* Make sure the container takes the full width */
  overflow: hidden; /* Hide any overflow */
}

.background-decoration {
  position: absolute;
  display: block;
  width: 100%; /* Set the width to 100% to make it scale with the container */
  height: auto; /* Maintain the aspect ratio while scaling down */
  transform: translate(-1.9%, -100.3%) scale(.967);
  transition: transform 0.3s ease; /* Add a smooth transition effect */
}


.fg-dec {
  width: 100%; /* Make sure the container takes the full width */
  overflow: hidden; /* Hide any overflow */
  
}

.foreground-decoration{
  position: absolute;
  display: block;
  width: 100%; /* Set the width to 100% to make it scale with the container */
  height: auto; /* Maintain the aspect ratio while scaling down */
  transform: translate(-17%, -62%) scale(0.41);
  transition: transform 0.3s ease; /* Add a smooth transition effect */
  z-index: 2;

}



.project-background {
  width: 100%; /* Make sure the container takes the full width */
  overflow: hidden; /* Hide any overflow */
  
}

.project-background-art {
  position: absolute;
  display: block;
  width: 100%; /* Set the width to 100% to make it scale with the container */
  height: auto; /* Maintain the aspect ratio while scaling down */
  transform: translate(24%, -98%) scale(0.4);
  transition: transform 0.3s ease; /* Add a smooth transition effect */
  filter: grayscale();
  

  
}




.carousel-root {
  position: relative; /* Change from absolute to relative */
  width: 100%;
  overflow: hidden;
  transform: translateY(-278%);
  transform: translateX();


}



.carousel-slider {
  position: relative;
  display: block;
  width: 100%; /* Set the width to 100% to make it scale with the container */
  height: auto; /* Maintain the aspect ratio while scaling down */
  transform: translate(-17%, 0%) scale(0.35);
  transition: transform 0.3s ease; /* Add a smooth transition effect */
  border-radius: 5%;
}




@media only screen and (max-width: 375px) {

  .logo-img {
    width: 25%;
    transform: translateY(20%);
    transition: all 0.35s ease;


  }

  .test1 {
    font-size: .32em;
    transition: all 0.35s ease;

  }
  


  .nav-container {
    position: fixed;
    width: 100%;
    height: 3.5vh;
    align-items: center;
    background-color: black;
    z-index: 10;
    transition: all 0.35s ease;

  }

  .links {
    margin-left: -370vw;
  }
 
  

  .nav-decoration {
    position: fixed;
    top: 10vh;
    margin-top: -4%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    transition: all 0.35s ease;

   
  }



  .project-blurb {
    font-size: .38rem;
    transition: all 0.35s ease;

  }





  




}



/* Media query for iPhone SE */
@media only screen and (max-width: 480px) {

  .logo-img {
    width: 95%;
    transform: translateY(20%);
    transition: all 0.35s ease;


  }

  .test1 {
    font-size: .32em;
    transition: all 0.35s ease;

  }
  


  .nav-container {
    position: fixed;
    width: 100%;
    height: 4.2vh;
    align-items: center;
    background-color: black;
    z-index: 10;
    transition: all 0.35s ease;

  }

  .links {
    margin-left: -0vw;
  }
 
  

  .nav-decoration {
    position: fixed;
    top: 10vh;
    margin-top: -17%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    transition: all 0.35s ease;

   
  }



  .project-blurb {
    font-size: .39rem;
    transition: all 0.35s ease;

  }


html {
  overflow-x: hidden;

}


.cordon1,
.cordon2,
.cordon3 {
  display: none;
}


  .cordon1 {
    transform: translateX(-39.8%) rotate(19.9deg);

  }

  .cordon2 {
    transform: translateX(-47.6%) rotate(-19.9deg);

  }

  .cordon3 {
    transform: translateX(-34.9%) rotate(17.6deg);

  }



  .carousel-root {
    position: relative; /* Change from absolute to relative */
    width: 100%;
    overflow: hidden;
    transform: translateY(-111%);
    z-index: 3;
    transition: all 0.35s ease;

  
  }


  .carousel-slider {
    position: relative;
    display: block;
    width: 100%; /* Set the width to 100% to make it scale with the container */
    height: auto; /* Maintain the aspect ratio while scaling down */
    transform: translate(-17%, 0%) scale(0.35);
    transition: transform 0.3s ease; /* Add a smooth transition effect */
    border-radius: 5%;
    }

    p.carousel-status {
      display: none;
    }
    
    ul.control-dots {
      display: none;
    }
    

  .carousel-root .thumbs-wrapper.axis-vertical {
    position: relative;
    display: block;
    transform: translate(30%, -150%) scale(.7);
    z-index: 4;

  }



  .blog-container {
    font-size: .33rem;
    transition: all 0.35s ease;

    
  }


 

}









/* Media Query for screens between iPhone 12 Pro and iPhone 14 Pro Max (381px - 430px) */

@media only screen and (min-width: 390px) and (max-width: 429px) {
  /* .cordon1,
.cordon2,
.cordon3 {
  display: none; 
}
   */
  

  .logo-img {
    width: 100%;
    transform: translateY(10%);
    transition: all 0.35s ease;

  }

  .nav-container {
    position: fixed;
    width: 100%;
    height: 3vh;
    align-items: center;
    background-color: black;
    z-index: 10;
    transition: all 0.35s ease;

  }

 
  .links {
    margin-left: -0vw;
  }

  .nav-decoration {
    position: fixed;
    top: 11vh;
    margin-top: -23%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    transition: all 0.35s ease;

   
  }

  .test1 {
    font-size: .35rem;
    transition: all 0.35s ease;

  }
 
  
  .project-blurb {
    font-size: .38rem;
    transition: all 0.35s ease;

  }
  
 
  

  .cordon1 {
    transform: translateX(-39.8%) rotate(19.9deg);

  }

  .cordon2 {
    transform: translateX(-47.6%) rotate(-19.9deg);

  }

  .cordon3 {
    transform: translateX(-35.4%) rotate(17.6deg);

  }


  .carousel-root {
    position: relative; /* Change from absolute to relative */
    width: 100%;
    overflow: hidden;
    transform: translateY(-112.8%);
    z-index: 3;
    transition: all 0.35s ease;

  
  }


  .carousel-slider {
    position: relative;
    display: block;
    width: 100%; /* Set the width to 100% to make it scale with the container */
    height: auto; /* Maintain the aspect ratio while scaling down */
    transform: translate(-17%, 0%) scale(0.35);
    transition: transform 0.3s ease; /* Add a smooth transition effect */
    border-radius: 5%;
    }

    p.carousel-status {
      display: none;
    }
    
    ul.control-dots {
      display: none;
    }
    

  .carousel-root .thumbs-wrapper.axis-vertical {
    position: relative;
    display: block;
    transform: translate(32%, -155%) scale(.69);
    z-index: 4;
  }



  .blog-container {
    font-size: .34rem;
    transition: all 0.35s ease;

  }



 } 



 @media only screen and (min-width: 425px){
  /* .cordon1,
.cordon2,
.cordon3 {
  display: none; 
}
   */
  

  .logo-img {
    width: 100%;
    transform: translateY(10%);
    transition: all 0.35s ease;

  }

  .nav-container {
    position: fixed;
    width: 100%;
    height: 3vh;
    align-items: center;
    background-color: black;
    z-index: 10;
    transition: all 0.35s ease;

  }

 
  .links {
    margin-left: -0vw;
  }

  .nav-decoration {
    position: fixed;
    top: 11vh;
    margin-top: -20%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    transition: all 0.35s ease;

   
  }

  .test1 {
    font-size: .37rem;
    transition: all 0.35s ease;

  }
 
  
  .project-blurb {
    font-size: .39rem;
    transition: all 0.35s ease;

  }
  
 
  

  .cordon1 {
    transform: translateX(-39.8%) rotate(19.9deg);

  }

  .cordon2 {
    transform: translateX(-47.6%) rotate(-19.9deg);

  }

  .cordon3 {
    transform: translateX(-35.4%) rotate(17.6deg);

  }


  .carousel-root {
    position: relative; /* Change from absolute to relative */
    width: 100%;
    overflow: hidden;
    transform: translateY(-114.8%);
    z-index: 3;
    transition: all 0.35s ease;

  
  }


  .carousel-slider {
    position: relative;
    display: block;
    width: 100%; /* Set the width to 100% to make it scale with the container */
    height: auto; /* Maintain the aspect ratio while scaling down */
    transform: translate(-17%, 0%) scale(0.35);
    transition: transform 0.3s ease; /* Add a smooth transition effect */
    border-radius: 5%;
    }

    p.carousel-status {
      display: none;
    }
    
    ul.control-dots {
      display: none;
    }
    

  .carousel-root .thumbs-wrapper.axis-vertical {
    transition: transform 0.3s ease; /* Add a smooth transition effect */

    position: relative;
    display: block;
    transform: translate(33%, -165%) scale(.69);
    z-index: 4;
  }



  .blog-container {
    font-size: .34rem;
    transition: all 0.35s ease;

  }



 } 




/* Media Query for screens  iPhone 14 Pro Max  (431px and above) */
@media only screen and (min-width: 481px) {

  .logo-img {
    width: 25%;
    transform: translateY(10%);
    transition: all 0.35s ease;

  }

  .nav-container {
    position: fixed;
    width: 100%;
    height: 3.5vh;
    align-items: center;
    background-color: black;
    z-index: 10;
    transition: all 0.35s ease;

  }

  .links {
    margin-left: -370vw;
  }
 
  

  .nav-decoration {
    position: fixed;
    top: 10vh;
    margin-top: -4%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    transition: all 0.35s ease;

  }
  
  .test1 {
    font-size: .39rem;
    transition: all 0.35s ease;

  }
 
  .project-blurb {
    font-size: .38rem;
    transition: all 0.35s ease;

  }
  

  .cordon1 {
    transform: translateX(-39.5%) rotate(19.9deg);

  }



  .cordon2 {
    transform: translateX(-47.5%) rotate(-19.9deg);

  }



  .cordon3 {
    transform: translateX(-35.9%) rotate(17.6deg);

  }


  .carousel-root {
    position: relative; /* Change from absolute to relative */
    width: 100%;
    overflow: hidden;
    transform: translateY(-117%);
    z-index: 3;
    transition: all 0.35s ease;

  
  }


  .carousel-slider {
    position: relative;
    display: block;
    width: 100%; /* Set the width to 100% to make it scale with the container */
    height: auto; /* Maintain the aspect ratio while scaling down */
    transform: translate(-17%, 0%) scale(0.35);
    transition: transform 0.3s ease; /* Add a smooth transition effect */
    border-radius: 5%;
    }

    p.carousel-status {
      display: none;
    }
    
    ul.control-dots {
      display: none;
    }
    

  .carousel-root .thumbs-wrapper.axis-vertical {
    position: relative;
    display: block;
    transform: translate(30%, -165%) scale(.6);
    z-index: 4;

  }



  .blog-container {
    font-size: .38rem;
    transition: all 0.35s ease;

    
  }



 } 








/* Media Query for iPad Air (821px - 1180px) */
@media only screen and (min-width: 768px)  {

  .logo-img {
    width: 75%;
    transform: translateY(10%);
    transition: all 0.35s ease;

  }

  .nav-container {
    position: fixed;
    width: 100%;
    height: 4vh;
    align-items: center;
    background-color: black;
    z-index: 10;
    transition: all 0.35s ease;

  }

  .links {
    margin-left: -0vw;
  }
 
  

  .nav-decoration {
    position: fixed;
    top: 1.5vh;
    margin-top: -2.5%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    transition: all 0.35s ease;

  }

.test1 {
  font-size: .6rem;
  transition: all 0.35s ease;

}

.project-blurb {
  font-size: .65rem;
  transition: all 0.35s ease;

}

/* html {
  overflow-x: hidden;

} */


/* .cordon1,
.cordon2,
.cordon3 {
  display: none;
} */




.cordon1 {
  transform: translateX(-39.9%) rotate(19.9deg);

}



.cordon2 {
  transform: translateX(-47.1%) rotate(-19.9deg);

}


.cordon3 {
  transform: translateX(-38.1%) rotate(17.6deg);

}


  .carousel-root {
    position: relative; /* Change from absolute to relative */
    width: 100%;
    overflow: hidden;
    transform: translateY(-139.5%);
    z-index: 3;
    transition: all 0.35s ease;

  
  }


  .carousel-slider {
    position: relative;
    display: block;
    width: 100%; /* Set the width to 100% to make it scale with the container */
    height: auto; /* Maintain the aspect ratio while scaling down */
    transform: translate(-17%, 0%) scale(0.35);
    transition: transform 0.3s ease; /* Add a smooth transition effect */
    border-radius: 5%;
    }

    p.carousel-status {
      display: none;
    }
    
    ul.control-dots {
      display: none;
    }
    

  .carousel-root .thumbs-wrapper.axis-vertical {
    position: relative;
    display: block;
    transform: translate(60%, -230%) scale(1.2);
    z-index: 4;

  }



.blog-container {
  font-size: .7rem;
  transition: all 0.35s ease;

}

  
    
}





/* Media Query for iPad Pro (1025px - 1366px) */
@media only screen and (min-width: 1024px) {


  .logo-img {
    width: 55%;
    transition: all 0.35s ease;

  }


  .links {
    margin-left: -0vw;
  }

  .nav-container {
    position: fixed;
    width: 100%;
    height: 6vh;
    align-items: center;
    background-color: black;
    z-index: 10;
    transition: all 0.35s ease;

  }


  .nav-decoration {
    position: fixed;
    top:4vh;
    margin-top: -4.5%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    transition: all 0.35s ease;

  }








  .test1 {
    font-size: .70rem;
    transition: all 0.35s ease;

  }
  





  .project-blurb {
    font-size: .7rem;
    transition: all 0.35s ease;

  }

  .cordon1 {
    transform: translateX(-39.9%) rotate(19.9deg);
  
  }
  
  
  
  .cordon2 {
    transform: translateX(-46.8%) rotate(-19.9deg);
  
  }
  
  
  .cordon3 {
    transform: translateX(-38.1%) rotate(17.6deg);
  
  }



  .carousel-root {
    position: relative; /* Change from absolute to relative */
    width: 100%;
    overflow: hidden;
    transform: translateY(-146%);
    z-index: 3;
    transition: all 0.35s ease;

  
  
  }


  .carousel-slider {
    position: relative;
    display: block;
    width: 100%; /* Set the width to 100% to make it scale with the container */
    height: auto; /* Maintain the aspect ratio while scaling down */
    transform: translate(-17%, 0%) scale(0.35);
    transition: transform 0.3s ease; /* Add a smooth transition effect */
    border-radius: 5%;
    }

    p.carousel-status {
      display: none;
    }
    
    ul.control-dots {
      display: none;
    }
    

  .carousel-root .thumbs-wrapper.axis-vertical {
    position: relative;
    display: block;
    transform: translate(72%, -265%) scale(1.4);
    z-index: 4;

  }


  .blog-container {
    font-size: .85rem;
    transition: all 0.35s ease;

  }

    
}








@media only screen and (min-width: 1280px) { 

  .logo-img {
    width: 50%;
    transition: all 0.35s ease;

  }


  .links {
    margin-left: -0vw;
  }

  .nav-container {
    position: fixed;
    width: 100%;
    height: 5vh;
    align-items: center;
    background-color: black;
    z-index: 10;
    transition: all 0.35s ease;

  }


  .nav-decoration {
    position: fixed;
    top:13vh;
    margin-top: -11%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    transition: all 0.35s ease;

  }


  .test1 {
    font-size: .8rem;
    transition: all 0.35s ease;

  }
  
 

  .project-blurb {
    font-size: 1.2rem;
    transition: all 0.35s ease;

  }



  .carousel-root {
    position: relative;
    width: 100%;
    overflow: hidden;
    transform: translateY(-152.5%);
    z-index: 3;
    transition: all 0.35s ease;

  }

  .carousel-slider {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    transform: translate(-17%, 0%) scale(0.35);
    transition: transform 0.3s ease;
    border-radius: 5%;
  }

  p.carousel-status {
    display: none;
  }
  
  ul.control-dots {
    display: none;
  }
  

  .carousel-root .thumbs-wrapper.axis-vertical {
    position: relative;
    display: block;
    transform: translate(80%, -350%) scale(1.5);
    z-index: 4;

  }


  .blog-container {
    font-size: 1.1rem;
    transition: all 0.35s ease;

  }

  
}






@media only screen and (min-width: 1366px) { 

  .logo-img {
    width: 60%;
    transition: all 0.35s ease;

  }


  .links {
    margin-left: -0vw;
  }

  .nav-container {
    position: fixed;
    width: 100%;
    height: 10vh;
    align-items: center;
    background-color: black;
    z-index: 10;
    transition: all 0.35s ease;

  }


  .nav-decoration {
    position: fixed;
    top:13vh;
    margin-top: -7%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    transition: all 0.35s ease;

  }


  .test1 {
    font-size: .8rem;
    transition: all 0.35s ease;

  }
  
 

  .project-blurb {
    font-size: 1.2rem;
    transition: all 0.35s ease;

  }



  .carousel-root {
    position: relative;
    width: 100%;
    overflow: hidden;
    transform: translateY(-152.5%);
    z-index: 3;
    transition: all 0.35s ease;

  }

  .carousel-slider {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    transform: translate(-17%, 0%) scale(0.35);
    transition: transform 0.3s ease;
    border-radius: 5%;
  }

  p.carousel-status {
    display: none;
  }
  
  ul.control-dots {
    display: none;
  }
  

  .carousel-root .thumbs-wrapper.axis-vertical {
    position: relative;
    display: block;
    transform: translate(80%, -350%) scale(1.5);
    z-index: 4;

  }


  .blog-container {
    font-size: 1.1rem;
    transition: all 0.35s ease;

  }

  
}
















 




@media only screen and (min-width: 1440px) {

  .logo-img {
    width: 60%;
    transition: all 0.35s ease;

  }


  .links {
    margin-left: -0vw;
    z-index: 50;
  }


  .nav-container {
    position: fixed;
    width: 100%;
    height: 8vh;
    align-items: center;
    background-color: black;
    z-index: 10;
    transition: all 0.35s ease;

  }


  .nav-decoration {
    position: fixed;
    top:5vh;
    margin-top: -4.5%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    transition: all 0.35s ease;

  }

  .test1 {
    font-size: .9rem;
    transition: all 0.35s ease;

  }
  
 

  .project-blurb {
    font-size: 1.2rem;
    transition: all 0.35s ease;

  }



  .carousel-root {
    position: relative;
    width: 100%;
    overflow: hidden;
    transform: translateY(-154%);
    z-index: 3;
    transition: all 0.35s ease;

  }

  .carousel-slider {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    transform: translate(-17%, 0%) scale(0.35);
    transition: transform 0.3s ease;
    border-radius: 5%;
  }

  p.carousel-status {
    display: none;
  }
  
  ul.control-dots {
    display: none;
  }
  

  .carousel-root .thumbs-wrapper.axis-vertical {
    position: relative;
    display: block;
    transform: translate(80%, -380%) scale(1.5);
    z-index: 4;

  }


  .blog-container {
    font-size: 1.2rem;
    transition: all 0.35s ease;

  }



  @media only screen and (min-resolution: 1.5dppx) {
    /* Additional styles for devices with a minimum pixel ratio of 2 */
    .logo-img {
      width: 45%;
      transform: translateY(5%);
      transition: all 0.35s ease;
  
    }


    .links {
      margin-left: -0vw;
    }

    .nav-container {
      position: fixed;
      width: 100%;
      height: 6vh;
      align-items: center;
      background-color: black;
      z-index: 10;
      transition: all 0.35s ease;
  
    }
  
  
    .nav-decoration {
      position: fixed;
      top:-0vh;
      margin-top: -1.5%;
      transform: translate(-50%, -50%);
      width: 100vw;
      height: 100vh;
      transition: all 0.35s ease;
  
    }

    p.carousel-status {
      display: none;
    }
    
    ul.control-dots {
      display: none;
    }
    
  


  }




  
}







@media only screen and (min-width: 1920px) {


.logo-img {
    width: 61%;
    transform: translateY(1%);
    transition: all 0.35s ease;

  }


  .links {
    margin-left: -0vw;
  }

  .nav-container {
    position: fixed;
    width: 100%;
    height: 8vh;
    align-items: center;
    background-color: black;
    z-index: 10;
    transition: all 0.35s ease;

  }


  .nav-decoration {
    position: fixed;
    top:6vh;
    margin-top: -4.5%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    transition: all 0.35s ease;

  }


  .test1 {
    font-size: 1.2rem;
    transition: all 0.35s ease;

  }
  
 

  .project-blurb {
    font-size: 1.4rem;
    transition: all 0.35s ease;

  }



  .carousel-root {
    position: relative;
    width: 100%;
    overflow: hidden;
    transform: translateY(-159.5%);
    transition: all 0.35s ease;

  }

  .carousel-slider {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    transform: translate(-17%, 0%) scale(0.35);
    transition: transform 0.3s ease;
    border-radius: 5%;
  }

  p.carousel-status {
    display: none;
  }
  
  ul.control-dots {
    display: none;
  }
  

  .carousel-root .thumbs-wrapper.axis-vertical {
    position: relative;
    display: block;
    transform: translate(100%, -490%) scale(1.9);
    z-index: 4;

  }


  .blog-container {
    font-size: 1.6rem;
    transition: all 0.35s ease;

  }



  @media only screen and (min-resolution: 1.5dppx) {
    /* Additional styles for devices with a minimum pixel ratio of 2 */
    .logo-img {
      width: 60%;
      transform: translateY(5%);
      transition: all 0.35s ease;
  
    }


    .links {
      margin-left: -0vw;
    }

    .nav-container {
      position: fixed;
      width: 100%;
      height: 9vh;
      align-items: center;
      background-color: black;
      z-index: 10;
      transition: all 0.35s ease;
  
    }
  
  
    .nav-decoration {
      position: fixed;
      top:0vh;
      margin-top: -1.5%;
      transform: translate(-50%, -50%);
      width: 100vw;
      height: 100vh;
      transition: all 0.35s ease;
  
    }

    p.carousel-status {
      display: none;
    }
    
    ul.control-dots {
      display: none;
    }
    
  


  }




}

/* Media query for very large desktop screens (2560px and above) */
@media only screen and (min-width: 2560px) {
  .logo-img {
    width: 61%;
    transform: translateY(5%);
    transition: all 0.35s ease;

  }


  .links {
    margin-left: -0vw;
  }

  .nav-container {
    position: fixed;
    width: 100%;
    height: 8vh;
    align-items: center;
    background-color: black;
    z-index: 10;
    transition: all 0.35s ease;

  }


  .nav-decoration {
    position: fixed;
    top:5vh;
    margin-top: -4.5%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    transition: all 0.35s ease;

  }


  .test1 {
    font-size: 1.6rem;
    transition: all 0.35s ease;

  }
  
 

  .project-blurb {
    font-size: 2rem;
    transition: all 0.35s ease;

  }



  .carousel-root {
    position: relative;
    width: 100%;
    overflow: hidden;
    transform: translateY(-162.5%);
    transition: all 0.35s ease;

  }

  .carousel-slider {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    transform: translate(-17%, 0%) scale(0.35);
    transition: transform 0.3s ease;
    border-radius: 5%;
  }

  p.carousel-status {
    display: none;
  }
  
  ul.control-dots {
    display: none;
  }
  

  .carousel-root .thumbs-wrapper.axis-vertical {
    position: relative;
    display: block;
    transform: translate(125%, -650%) scale(2.4);
    z-index: 4;

  }


  .blog-container {
    font-size: 2rem;
    transition: all 0.35s ease;

  }

  @media only screen and (min-resolution: 1.5dppx) {
    /* Additional styles for devices with a minimum pixel ratio of 2 */
    .logo-img {
      width: 16%;
      transform: translateY(5%);
      transition: all 0.35s ease;
  
    }


    .links {
      margin-left: -370vw;
    }

    .nav-container {
      position: fixed;
      width: 100%;
      height: 8vh;
      align-items: center;
      background-color: black;
      z-index: 10;
      transition: all 0.35s ease;
  
    }
  
  
    .nav-decoration {
      position: fixed;
      top:5vh;
      margin-top: -1%;
      transform: translate(-50%, -50%);
      width: 100vw;
      height: 100vh;
      transition: all 0.35s ease;
  
    }

    p.carousel-status {
      display: none;
    }
    
    ul.control-dots {
      display: none;
    }
    
  


  }


}

@media only screen and (min-width: 3840px) {
  .logo-img {
    width: 60%;
    transform: translateY(5%);
    transition: all 0.35s ease;

  }


  .links {
    margin-left: -0vw;
  }

  .nav-container {
    position: fixed;
    width: 100%;
    height: 8vh;
    align-items: center;
    background-color: black;
    z-index: 10;
    transition: all 0.35s ease;

  }


  .nav-decoration {
    position: fixed;
    top:48vh;
    margin-top: -27%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    transition: all 0.35s ease;

  }


  .test1 {
    font-size: 2.5rem;
    transition: all 0.35s ease;

  }
  
 

  .project-blurb {
    font-size: 3rem;
  }



  .carousel-root {
    position: relative;
    width: 100%;
    overflow: hidden;
    transform: translateY(-167%);
    transition: all 0.35s ease;

  }

  .carousel-slider {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    transform: translate(-17%, 0%) scale(0.35);
    transition: transform 0.3s ease;
    border-radius: 5%;
  }

  p.carousel-status {
    display: none;
  }
  
  ul.control-dots {
    display: none;
  }
  

  .carousel-root .thumbs-wrapper.axis-vertical {
    position: relative;
    display: block;
    transform: translate(154%, -890%) scale(3);
    z-index: 4;

  }


  .blog-container {
    font-size: 3rem;
    transition: all 0.35s ease;

  }



}



/* li.thumb {
  outline: 2px solid #EADBC8; 
} */






p.carousel-status {
  display: none;
}

ul.control-dots {
  display: none;
}




.carousel {
  width: 100%; /* Make sure the container takes the full width */
  transition: transform 0.3s ease; /* Add a smooth transition effect */
}



.thumbs-wrapper.axis-vertical{
  position: relative;
  display: block;
  transform: translate(70%, -390%) scale(1.3);
}

.custom-thumb {
  
}

/* .custom-arrow-prev {
  color: transparent;
  position: relative;
  transform: translate(2600%, 1215%) scale(1.5);
  z-index: 6;
}


.custom-arrow-next {
  color: transparent;
  position: relative;
  transform: translate(4100%, -85%) scale(1.5);
  z-index: 6;
} */






.project-blurb {
  position: absolute;
  display: block;
  width: 100%;
  height: auto;
  transform: translate(55%, -110%);
  transition: transform 0.3s ease;
  overflow: hidden;
  z-index: 2;
  color: #EADBC8;


}

.project-title {
  font-size: 2em;
}

.project-desc {
  width: 40%;
  font-size: 1.2em;
}






.bg-wall-art {
  width: 100%; /* Make sure the container takes the full width */
  overflow: hidden; /* Hide any overflow */

}

.blog-wall-art {
  position: relative;
  display: block;
  width: 100%; /* Set the width to 100% to make it scale with the container */
  height: auto; /* Maintain the aspect ratio while scaling down */
  transform: translate(0%, 15%) scale(1);
  transition: transform 0.3s ease; /* Add a smooth transition effect */
}








.blg-decoration {
  width: 100%; /* Make sure the container takes the full width */
  display: grid;
  transform: translate(-13%, -65%) scale(.48);
  z-index: 20;

}

.blog-decoration {
  position: relative;
  display: block;
  width: 100%; /* Set the width to 100% to make it scale with the container */
  height: auto; /* Maintain the aspect ratio while scaling down */
  transition: transform 0.3s ease; /* Add a smooth transition effect */
  z-index: 20;

}




/* 
.blog-bg {
  position: absolute;
  width: 100%;
  transform: translate(29.5%, -600%) ;

  overflow: hidden;
  
}

.blog-background {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform 0.3s ease;
} */




.blog-bg {
  width: 100%;
  overflow: hidden;
  transform: translate(-12%, -255%) scale(.5);
  z-index: 60;
}

.blog-background {
  position: absolute;
  display: block;
  width: 90%;
  height: auto;
  transition: transform 0.3s ease;
}



/* .fg-dec {
  width: 100%;
  overflow: hidden;
}

.foreground-decoration{
  position: absolute;
  display: block;
  width: 100%;
  height: auto;
  transform: translate(-17%, -62%) scale(0.41);
  transition: transform 0.3s ease;
} */






.blog-container {
  position: relative;
  display: block;
  width: 50%;
  transition: transform 0.3s ease;
  margin-top: -95%; /* Adjust this percentage to move the container up or down */
  margin-left: 15%; /* Adjust this percentage to move the container to the right */
  z-index: 2;

}



#container {
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
}

.read-more {
}

.blog-title {
  color: #EADBC8;
  cursor: pointer;

}


/* Variables */
:root {
  --bg: #f3f8fa;
  --white: #fff;
  --black: #ED8C48;
  --transition-duration: 0.45s;
  --transition-ease: cubic-bezier(0.65, 0, 0.076, 1);
}

/* Global Styles */
* {
  box-sizing: border-box;
}

body {
  font-family: 'Mukta', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  min-height: 100vh;
  background: var(--bg);
  background-color: #393939;
}

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

/* Read More Button */
button.read-more {
  width: 12vw; /* Adjust width based on design preferences */
  height: auto;
}

/* Circle Styles */
button.read-more .circle {
  transition: width 0.8s var(--transition-ease);
  position: relative;
  display: block;
  margin: 0;
  width: 3vw; /* Adjust width based on design preferences */
  height: 3vw; /* Adjust height based on design preferences */
  background: var(--black);
  border-radius: 1.625vw; /* Adjust border-radius based on design preferences */
}

/* Icon Styles */
button.read-more .circle .icon {
  transition: background 0.8s var(--transition-ease);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: var(--white);
}

/* Arrow Styles */
button.read-more .circle .icon.arrow {
  transition: background 0.8s var(--transition-ease), transform 0.8s var(--transition-ease);
  left: 0.625vw; /* Adjust left position based on design preferences */
  width: 1.125vw; /* Adjust width based on design preferences */
  height: 0.125vw; /* Adjust height based on design preferences */
}

button.read-more .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.25vw; /* Adjust top position based on design preferences */
  right: 0.0625vw; /* Adjust right position based on design preferences */
  width: 0.625vw; /* Adjust width based on design preferences */
  height: 0.625vw; /* Adjust height based on design preferences */
  border-top: 0.125vw solid var(--white);
  border-right: 0.125vw solid var(--white);
  transform: rotate(45deg);
}

/* Button Text Styles */
button.read-more .button-text {
  transition: color 0.8s var(--transition-ease);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75vw 0;
  margin: 0 0 0 1.85vw; /* Adjust margin based on design preferences */
  color: #EADBC8;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

/* Hover Styles */
button:hover .circle {
  width: 100%;
}

button:hover .circle .icon.arrow {
  background: var(--white);
  transform: translate(1vw, 0); /* Adjust translation based on design preferences */
}

button:hover .button-text {
  color: var(--white);
}

/* Grid Styles */
@supports (display: grid) {
  body {
    /* display: grid; */
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.625rem;
    grid-template-areas: ". main main ." ". main main .";
  }

  #container {
    /* background-color: red; */
    grid-area: main;
    align-self: center;
    justify-self: center;
  }
}






/* ////////////////// */






